import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Hydro from "/src/components/img/hydro"
import ImgCon from "/src/components/img-con"
import SEO from "/src/components/seo"
import BtnWrap from "/src/components/btn-wrap"

import Hydroo1 from "/src/components/img/hydroo/1"
import Hydroo2 from "/src/components/img/hydroo/2"


export default () => (
  <Layout>
    <SEO title="Co je světový oceán?" />
    <h1>Co je světový oceán?</h1>
    <p><strong>Světov&yacute; oce&aacute;n</strong> je v&scaron;echna voda soustředěn&aacute; v&nbsp;oce&aacute;nech a moř&iacute;ch, zab&iacute;r&aacute; zhruba &frac34; zemsk&eacute;ho povrchu. Jde o otevřen&yacute; dynamick&yacute; syst&eacute;m, v&nbsp;kter&eacute;m neust&aacute;le prob&iacute;h&aacute; v&yacute;měna l&aacute;tek a energie, a to v&nbsp;něm sam&eacute;m, ale i mezi n&iacute;m a ostatn&iacute;mi geosf&eacute;rami. Tato v&yacute;měna se realizuje formou celkov&yacute;ch glob&aacute;ln&iacute;ch oběhů, na kter&yacute;ch se z&uacute;častňuje tepeln&aacute; energie, zemsk&aacute; přitažlivost, vl&aacute;ha, různ&eacute; soli a plyny.</p>
    <p>D&iacute;ky vysok&eacute; tepeln&eacute; kapacitě vody světov&eacute;ho oce&aacute;nu jde o <strong>obrovsk&yacute; akumul&aacute;tor a regul&aacute;tor tepla</strong>. M&aacute; prvořadou &uacute;lohu v&nbsp;oběhu sr&aacute;žek a plynů, předev&scaron;&iacute;m pak kysl&iacute;ku a CO<sub>2</sub>.</p>
    <hr /><h2>Pohyby vody světov&eacute;ho oce&aacute;nu</h2>
    <p>Voda v&nbsp;oce&aacute;nu je v&nbsp;neust&aacute;l&eacute;m pohybu, a to ať už d&iacute;ky působen&iacute; větru, rozd&iacute;lů v&nbsp;teplotě a salinitě vody, vyrovn&aacute;v&aacute;n&iacute; vody v&nbsp;někter&yacute;ch oblastech či slapov&yacute;ch jevů a dal&scaron;&iacute;ch. Pohyb vody lze rozdělit takto:</p>
    <ul>
    <li><strong>vlněn&iacute; vody</strong> = pohyb vodn&iacute;ch č&aacute;stic po uzavřen&yacute;ch drah&aacute;ch</li>
    <li><strong>prouděn&iacute; vody, mořsk&eacute; proudy</strong> a <strong>slapov&eacute; jevy</strong> = pohyb vodn&iacute;ch č&aacute;stic ve vertik&aacute;ln&iacute;m či horizont&aacute;ln&iacute;m směru</li>
    </ul>
    <p>Obzvl&aacute;&scaron;ť důležit&eacute; z&nbsp;hlediska pohybu vody jsou <strong>povrchov&eacute; proudy</strong>, kter&eacute; společně s&nbsp;vertik&aacute;ln&iacute;mi a hlubinn&yacute;mi vytv&aacute;ř&iacute; <strong>v&scaron;eobecnou cirkulaci vod světov&eacute;ho oce&aacute;nu</strong>.</p>
    <p>Ta mimo jin&eacute; např&iacute;klad způsobuje <strong>v&yacute;měnu vody</strong> ve světov&eacute;m oce&aacute;nu, <strong>přen&aacute;&scaron;en&iacute; ledu</strong> do niž&scaron;&iacute;ch geografick&yacute;ch &scaron;&iacute;řek, ovlivňuje <strong>rozdělen&iacute; teploty, salinity, planktonu aj.</strong> v&nbsp;něm (jde o tzv. termohalinn&iacute; cirkulace), ovlivňuje <strong>cirkulaci atmosf&eacute;ry</strong>, <strong>podneb&iacute;</strong> jednotliv&yacute;ch oblast&iacute; a t&iacute;m měn&iacute; i okraje sou&scaron;e.</p>
    <hr /><h2>Oce&aacute;nsk&eacute; a mořsk&eacute; proudy</h2>
    <p>Oce&aacute;nsk&eacute; a mořsk&eacute; proudy podmiňuj&iacute; přenos velk&eacute;ho množstv&iacute; vody, a to v&nbsp;horizont&aacute;ln&iacute;m i vertik&aacute;ln&iacute;m směru. <strong>Vznikaj&iacute; vlivem</strong> mnoha <strong>sil</strong>, mezi něž např&iacute;klad patř&iacute;:</p>
    <ul>
    <li><strong>pravideln&eacute;, st&aacute;l&eacute; větry,</strong></li>
    <li>rozd&iacute;ln&eacute; <strong>fyzik&aacute;ln&iacute; a chemick&eacute; vlastnosti vody,</strong></li>
    <li><strong>setrvačnost větrn&yacute;ch proudů,</strong></li>
    <li><strong>přitažliv&aacute; s&iacute;la Slunce a Měs&iacute;ce,</strong></li>
    <li><strong>rozd&iacute;ly v&nbsp;&uacute;rovni hladiny</strong>.</li>
    </ul>
    <p>Na směr proudů maj&iacute; pak vliv i dal&scaron;&iacute; faktory, př&iacute;kladem může b&yacute;t Coriolisova s&iacute;la, odstřediv&aacute; s&iacute;la zemsk&eacute; rotace, třen&iacute; vody o dno, pobřež&iacute; i vnitřn&iacute; třen&iacute; vody, reli&eacute;f dna a dal&scaron;&iacute;. Proudy lze d&aacute;le dělit, a to dle <strong>jejich st&aacute;losti:</strong></p>
    <ul>
    <li><strong>st&aacute;l&eacute; proudy </strong>(př. Golfsk&yacute; proud, Kuro-&scaron;io či pas&aacute;tov&eacute; proudy)</li>
    <li><strong>periodick&eacute; proudy </strong>(sez&oacute;nně, nebo v&nbsp;určit&eacute;m obdob&iacute; měn&iacute; směr &ndash; př. monzunov&eacute; či př&iacute;livo-odlivov&eacute; proudy)</li>
    <li><strong>nest&aacute;l&eacute;</strong> (n&aacute;hodn&eacute;, vznikaj&iacute; nahodil&yacute;mi jevy).</li>
    </ul>
    <p>D&aacute;le rozli&scaron;ujeme proudy <strong>dle v&yacute;skytu</strong> na:</p>
    <ul>
    <li>povrchov&eacute;,</li>
    <li>hlubinn&eacute;,</li>
    <li>dnov&eacute;,</li>
    <li>pobřežn&iacute;.</li>
    </ul>
    <p>Podle <strong>vlastnost&iacute; pohybu</strong> rozčleňujeme proudy na:</p>
    <ul>
    <li>př&iacute;m&eacute;,</li>
    <li>cyklon&aacute;ln&iacute;,</li>
    <li>anticyklon&aacute;ln&iacute;.</li>
    </ul>
    <p>A konečně dle <strong>fyzik&aacute;lně-chemick&yacute;ch vlastnost&iacute;</strong> vyčleňujeme proudy <strong>v&yacute;stupn&eacute;</strong> (vystupuj&iacute; k&nbsp;hladině, tzv. upwelling) a <strong>sestupn&eacute;</strong> (sestupuj&iacute; ke dnu, tzv. downwelling) a rovněž proudy tepl&eacute; a studen&eacute;.</p>
    <p><strong>Tepl&eacute; oce&aacute;nsk&eacute; proudy</strong> maj&iacute; na podneb&iacute; z&aacute;sadn&iacute; vliv, vznik&aacute; nad nimi cyklon&aacute;ln&iacute; cirkulace, kter&aacute; podmiňuje bohatou sr&aacute;žkovou činnost. Rovněž mohou v&yacute;razně oteplovat podneb&iacute; ve sv&eacute;m okol&iacute; &ndash; nejtypičtěj&scaron;&iacute;m př&iacute;kladem je pr&aacute;vě <strong>Golfsk&yacute; proud</strong> a jeho vliv na evropsk&eacute; podneb&iacute;. Dal&scaron;&iacute;mi tepl&yacute;mi proudy jsou např. Kuro-&scaron;io (Tich&yacute; oce&aacute;n), Brazilsk&yacute; (Atlantik) či Agulhask&yacute; (Indick&yacute; oce&aacute;n).</p>
    <p>Nad <strong>studen&yacute;mi oce&aacute;nsk&yacute;mi proudy</strong> se naopak vytv&aacute;ř&iacute; vysok&yacute; tlak, anticyklony, což v&yacute;razně omezuje množstv&iacute; spadl&yacute;ch sr&aacute;žek. Vlivem <strong>Humboldtova proudu </strong>je např&iacute;klad při pobřež&iacute; Chile pou&scaron;ť Atacama a <strong>Benguelsk&eacute;ho</strong> při pobřež&iacute; Afriky pou&scaron;ť Namib. Dal&scaron;&iacute;mi studen&yacute;mi proudy jsou např&iacute;klad Z&aacute;padoaustralsk&yacute; proud (Indick&yacute; oce&aacute;n), Kalifornsk&yacute; proud (Tich&yacute; oce&aacute;n) či Kan&aacute;rsk&yacute; proud (Atlantik).</p>
    <hr /><h2>Ekonomick&eacute; využit&iacute; světov&eacute;ho oce&aacute;nu</h2>
    <p>Světov&yacute; oce&aacute;n m&aacute; pro lidstvo &scaron;irok&eacute; hospod&aacute;řsk&eacute; využit&iacute;, ať už jde o oce&aacute;n jako <strong>zdroj nerostn&yacute;ch surovin, energie, potravy</strong> i <strong>pitn&eacute; vody</strong>, ale i oce&aacute;n jako velmi důležitou <strong>dopravn&iacute; cestu</strong>.</p>
    <p>V&nbsp;oce&aacute;nu se těž&iacute; předev&scaron;&iacute;m <strong>ropa</strong> (Persk&yacute;, Venezuelsk&yacute;, Guinejsk&yacute; z&aacute;liv a Severn&iacute; moře), <strong>uhl&iacute;</strong> (u Velk&eacute; Brit&aacute;nie, Japonska), <strong>železn&aacute; ruda </strong>(u Francie, Kanady), <strong>měď a nikl</strong> (Hudsonův z&aacute;liv) a mnoho dal&scaron;&iacute;ch miner&aacute;lů. Z&nbsp;oce&aacute;nů pak poch&aacute;z&iacute; 1/3 těžby kuchyňsk&eacute; soli, 2/3 hořč&iacute;ku a 9/10 bromu.</p>
    <p>Ekonomicky velmi v&yacute;znamn&yacute;m je <strong>rybolov</strong>, ročně se z&nbsp;oce&aacute;nu z&iacute;sk&aacute; 60 až 66 mil. tun ryb. Bohat&aacute; lovi&scaron;tě existuj&iacute; předev&scaron;&iacute;m při v&yacute;chodn&iacute;ch pobřež&iacute;ch, kde existuj&iacute; v&yacute;stupn&eacute; proudy (plankton a dal&scaron;&iacute; l&aacute;tky). Asi <strong>77 %</strong> rybolovu je soustředěno <strong>na severn&iacute; polokouli</strong>. V&nbsp;dne&scaron;n&iacute; době existuj&iacute; t&eacute;ž ryb&iacute; farmy při pobřež&iacute;ch pevniny.</p>
    <p>D&iacute;ky neust&aacute;l&eacute;mu pohybu m&aacute; oce&aacute;n velik&yacute; <strong>potenci&aacute;l pro v&yacute;robu energie</strong>. V&nbsp;současnosti zat&iacute;m existuj&iacute; tzv. <strong>př&iacute;livov&eacute; elektr&aacute;rny</strong> funguj&iacute;c&iacute; na b&aacute;zi př&iacute;livu a odlivu, kter&eacute; jsou lokalizov&aacute;ny např&iacute;klad ve Francii a Rusku.</p>
    <p><strong>Destilac&iacute;</strong> lze pak z&nbsp;oce&aacute;nu z&iacute;sk&aacute;vat <strong>sladkou vodu</strong>. V&nbsp;Kuvajtu je takto z&iacute;sk&aacute;v&aacute;no 96 % sladk&eacute; vody, podobn&aacute; zař&iacute;zen&iacute; jsou lokalizov&aacute;na i v&nbsp;jin&yacute;ch zem&iacute;ch &ndash; např&iacute;klad v&nbsp;&Iacute;r&aacute;nu, Kalifornii či Rusku.</p>
    <p>Na oce&aacute;nu jakožto dopravn&iacute; tepně pak z&aacute;vis&iacute; glob&aacute;ln&iacute; obchod, a to jak na <strong>kontejnerov&eacute; přepravě</strong>, tak přepravě pomoc&iacute; <strong>tankerů</strong>. Velikou &uacute;lohu hraj&iacute; Suezsk&yacute; a Panamsk&yacute; průplav. Konkr&eacute;tn&iacute; trasy a př&iacute;stavy pro jednotliv&eacute; oce&aacute;ny jsou pops&aacute;ny v&nbsp;kapitole o <Link to="/hydrologie/cleneni-svetoveho-oceanu/">členěn&iacute; světov&eacute;ho oce&aacute;nu</Link>.</p>
    <hr />
    <ImgCon><Hydro /><div>
    <h2>Studijn&iacute; materi&aacute;ly Hydrologie</h2>
    <p>T&eacute;ma <strong>Světový oceán</strong> je souč&aacute;st&iacute; <Link to="/hydrologie/">studijn&iacute;ch materi&aacute;lů Hydrologie a hydrogeografie</Link>. Tento předmět byl vyučov&aacute;n na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat (1.&nbsp;tlač&iacute;tko), nebo si přečtěte předešlé (2.&nbsp;tlač&iacute;tko) či následující (3. tlačítko) t&eacute;ma.</p>
    <BtnWrap>
    <Link to="/hydrologie/"><button>Rozcestník hydrologie</button></Link>
    <Link to="/hydrologie/mokrady/"><button className="inv">&larr; Mokřady</button></Link>
    <Link to="/hydrologie/cleneni-svetoveho-oceanu/"><button className="inv">Členění světového oceánu &rarr;</button></Link>
    </BtnWrap>
    </div></ImgCon><hr />

  </Layout>
)
